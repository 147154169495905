const request = require('superagent')


const out = {
  root: '/api/'
}

let host = ''
if (location.hostname === 'localhost') {
  host = 'http://localhost:3002'
}

out.getData = self => {
  let day = self.$route.meta.day
  let req = request.get(`${host}/api/${day}`)
    .set('accept', 'json')
  if (self.$route.query.seed) {
    req.query({'seed': self.$route.query.seed})
  }
  return req.then(res => {
    self.seed = res.body.seed
    self.result = res.body.result
    return Promise.resolve(res.body)
  })

}

export default out
