<template>
  <div>
    <day-wrapper :seed="seed" :reload="loadData">
      <div>
        <svg 
          viewBox="0 0 700 460" style="maxWidth: 700px;">
          <polyline v-for="(u, idx) in result.top" :key="'top'+idx" :points="topRowToothPositions(u.height, idx)"
            :fill="u.colour" stroke="black" stroke-width="2" stroke-linejoin="round"
          />
          <polyline v-for="(u, idx) in result.bottom" :key="'bottom'+idx" :points="lowerRowToothPositions(u.height, idx)"
            :fill="u.colour" stroke="black" stroke-width="2" stroke-linejoin="round"
          />
          <g v-for="(tooth, idx) in result.topProblems" :key="`topTeethProblems${idx}`">
            <text :x="(700 / 4) * (idx + 1)" :y="idx * 20 + 20" class="small"
              text-anchor="middle">
              {{ tooth.problem }}
            </text>
            <line
              :x1="(700 / 4) * (idx + 1)" :y1="idx * 20 + 25"
              :x2="midSpot + (tooth.tooth + 0.5) * toothWidth " :y2="toothTop - 5"
              style="stroke:rgb(255,0,0);stroke-width:2"
            />
          </g>
          <g v-for="(tooth, idx) in result.bottomProblems" :key="`bottomTeethProblems${idx}`">
            <text :x="(700 / 4) * (idx + 1)" :y="toothBottom + idx * 20 + 20 - lowerShift" class="small"
              text-anchor="middle">
              {{ tooth.problem }}
            </text>
            <line
              :x1="(700 / 4) * (idx + 1)" :y1="toothBottom + idx * 20 - lowerShift"
              :x2="midSpot + (tooth.tooth + 0.5) * toothWidth " :y2="toothBottom - toothTop + 5"
              style="stroke:rgb(255,0,0);stroke-width:2"
            />
          </g>
        </svg>
      </div>
    </day-wrapper>
  </div>
</template>

<script>
import DayWrapper from './DayWrapper'
import Server from './server'
export default {
  components: {
    DayWrapper
  },
  data: function() {
    return {
      toothWidth: 30,
      toothMinHeight: 10,
      toothMultiplier: 3,
      toothTop: 120,
      toothBottom: 400,
      lowerShift: 50,
      seed: "",
      result: {
        top:[1,2,3,3,2,1],
        bottom:[1,2,3,3,2,1],
        fangHeight: 10
      }
    };
  },
  computed: {
    midSpot () {
      return (700 / 2) - this.toothWidth * 8
    }
  },
  mounted () {
    this.loadData()
  },
  methods: {
    loadData() {
      Server.getData(this)
    },
    topRowToothPositions(height, index) {
      let t = this.toothTop
      let h = this.toothMinHeight + height * this.toothMultiplier
      let l = this.midSpot + this.toothWidth * index + 5
      let r = this.midSpot + this.toothWidth * (index + 1) + 5
      let positions = [
        [l, t],
        [l, t + h],
      ]
      if (index === 5 || index === 10) {
        positions.push([(r + l) / 2, t + h + this.result.fangHeight])
      }
      positions.push([r, t + h])
      positions.push([r, t])
      return positions.map(u => u.join(',')).join(' ')
    },
    lowerRowToothPositions(height, index) {
      let h = this.toothMinHeight + height * this.toothMultiplier
      let l = this.midSpot + this.toothWidth * index + 5
      let r = this.midSpot + this.toothWidth * (index + 1) + 5
      let bottom = this.toothBottom - this.toothTop
      let positions = [
        [l, bottom],
        [l, bottom - h],
      ]
      positions.push([r, bottom - h])
      positions.push([r, bottom])
      return positions.map(u => u.join(',')).join(' ')
    }
  },
  watch:{
    $route (to, from){
      this.loadData()
    }
  } 
};
</script>

<style>
.upperTooth, .lowerTooth {
  background-color: white;
  border: 1px solid black;
  width: 30px;
  display: inline-block;
}

.toothWrapper {
  position: relative;
}

.upperTooth {
  border-radius: 0px 0px 4px 4px;
}

.lowerTooth {
  position: relative;
  border-radius: 4px 4px 0px 0px;
}

.svgItem {
  width: 100%;
}

</style>
