<template>
  <div id="app">
    <h1>
      13 Days 13 Shorts 2018
    </h1>
    <days-header></days-header>
    <router-view></router-view>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import DaysHeader from './components/DaysHeader.vue'
export default {
  name: 'app',
  components: {
    HelloWorld,
    DaysHeader
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: black;
  margin-top: 60px;
  outline: 3px solid red;
  background-color: lightcoral;
  padding: 10px;
}

body {
  background-color: black;
}
</style>
