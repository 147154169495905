<template>
  <div>
    <day-wrapper :seed="seed" :reload="loadData">
      <div class="hotelNameGroup">
        <span class="hotelName">{{ hotelName }}</span><span>&nbsp;({{ stars}}.{{subStars}})</span>
      </div>
      <div class="review">
        <div>
          <div class="reviewer">
          {{ reviewer }}
          </div>
          <div v-for="n in stars" :key="`pos` + n" class="starLight">
            ★
          </div>
          <div v-for="n in subStars" :key="`neg` + n" class="starDark">
            ☆
          </div>
        </div>
        <div v-for="(text, idx) in result" :key="idx">
          {{text}}
        </div>
      </div>
    </day-wrapper>
  </div>
</template>

<script>
import DayWrapper from './DayWrapper'
import Server from './server'
const title = 'Haunted Hotel'
export default {
  components: {
    DayWrapper
  },
  data: function() {
    return {
      seed: "",
      result: [
      ],
      theme: title,
      stars: 0,
      subStars: 5,
      reviewer: '',
      hotelName: ''
    };
  },
  mounted () {
    this.loadData()
  },
  methods: {
    loadData() {
      Server.getData(this).then(data => {
        this.stars = data.stars
        this.subStars = 5 - this.stars
        this.reviewer = data.reviewer
        this.hotelName = data.hotelName
      })
    }
  },
  watch:{
    $route (to, from){
      if (from)
      this.loadData()
    }
  } 
};
</script>

<style>
.starLight, .starDark, .reviewer {
  display: inline;
  font-size: 1.8em;
}
.starLight, .starDark {
  color: yellow;
}

.review {
  border: 1px solid black;
  text-align: left;
  margin-left: 10px;
  margin-right: 10px;
}

.hotelNameGroup {
  font-size: 2.4em;
}

.hotelName {
  font-weight: bold;
}

</style>
