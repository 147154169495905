<template>
  <div>
    <h1>{{this.$route.meta.theme}}</h1>
    <button v-on:click="doReload">New {{ this.$route.meta.theme }}
    </button>
    <slot>
    </slot>
    <div class="permalink-blob">
      <div>
        Permanent Link
      </div>
      <div>
        <input readonly type="text" :value="link" @focus="$event.target.select()" />
      </div>
    </div>
    <div>
      <center>Dezzles on <a target="_blank" href="https://twitter/Dezzles">Twitter</a></center>
      <center>Dezzles on <a target="_blank" href="https://youtube.com/Dezzles">YouTube</a></center>
      <center>Sangeetha on <a target="_blank" href="https://twitter/SangPillai">Twitter</a></center>
    </div>
  </div>
</template>


<script>
export default {
  data: function () {
    return {
    }
  },
  props: [
    'reload',
    'seed'
  ],
  methods: {
    doReload () {
      this.$route.query.seed = null
      this.reload()
    }
  },
  mounted() {
    document.title = `${this.$route.meta.theme} - 13 Days 13 Shorts`
  },
  computed: {
    link() {
      return `${location.protocol}//${location.host}/#/${this.$route.meta.day}?seed=${this.seed}`
    }
  }
}
</script>

<style scoped>
li {
  display: inline;
}

ul {
  list-style-type: none;
}

.permalink-blob {
  margin-top: 20px;
}
</style>
