import Vue from 'vue'
import Router from 'vue-router'
import HelloWorld from '@/components/HelloWorld'
import Day19 from '@/components/Day19'
import Day20 from '@/components/Day20'
import Day21 from '@/components/Day21'
import Day22 from '@/components/Day22'
import Day23 from '@/components/Day23'
import Day24 from '@/components/Day24'
import Day25 from '@/components/Day25'
import Day26 from '@/components/Day26'
import Day27 from '@/components/Day27'
import Day28 from '@/components/Day28'
import Day29 from '@/components/Day29'
import Day30 from '@/components/Day30'
import Day31 from '@/components/Day31'

const themes = [
  "Haunted Hotel Reviews",
  "Ghastly Legends",
  "Vampire With A Toothache",
  "Midnight",
  "Howls of the Dead",
  "Bones as a Musical Instruments",
  "The End of Harvest",
  "Foggy Lake",
  "A Wraith, A Mummy, A Zombie Walk Into a Bar",
  "Henry Selick",
  "Witches in Love",
  "Spiders",
  "Halloween Party",
]

const daysView = [
  Day19,
  Day20,
  Day21,
  Day22,
  Day23,
  Day24,
  Day25,
  Day26,
  Day27,
  Day28,
  Day29,
  Day30,
  Day31
]

let routes = [
  {
    path: '/',
    name: 'HelloWorld',
    component: HelloWorld
  }  
]

themes.forEach((theme, idx) => {
  let day = idx + 19
  routes.push({
    path: '/' + day,
    name: 'Day' + day,
    component: daysView[idx],
    meta: {
      theme,
      day
    }
  })
})

Vue.use(Router)
export default new Router({
  routes
})
