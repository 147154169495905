<template>
  <div>
    <day-wrapper :seed="seed" :reload="loadData">
      <div class="wrapper">
        <div v-for="(text, idx) in result" :key="idx">
          <div class="firstLetter">{{text[0]}}</div><div class="rest">{{text.substr(1)}}</div>
        </div>
      </div>
    </day-wrapper>
  </div>
</template>

<script>
import DayWrapper from './DayWrapper'
import Server from './server'
export default {
  components: {
    DayWrapper
  },
  data: function() {
    return {
      seed: "",
      result: [
      ]
    };
  },
  mounted () {
    this.loadData()
  },
  methods: {
    loadData() {
      Server.getData(this)
    }
  },
  watch:{
    $route (to, from){
      this.loadData()
    }
  } 
};
</script>

<style>
.firstLetter {
  font-size: 3em;
  font-weight: bolder;
  width: 50px;
  text-align: center;
}
.rest {
  font-size: 2.6em;
  font-weight: lighter;
  text-align: left;
}

.firstLetter, .rest {
  display: inline-block;
}

.wrapper {
  width: fit-content;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  text-align: left;
}
</style>
