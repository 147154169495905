<template>
  <ul> |
    <li v-for="(day, idx) in days" :key="idx" v-if="dayVisible[idx]">
      <router-link :to="(idx + 19) + ''" >{{ day }}</router-link> |
    </li>
  </ul>
</template>


<script>
export default {
  data: function () {
    return {
      dayVisible: [
        true,
        false,
        true,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
      days: [
        "Haunted Hotel",
        "Ghastly Legends",
        "Vampire With A Toothache",
        "Midnight",
        "Howls of the Dead",
        "Bones as a Musical Instruments",
        "The End of Harvest",
        "Foggy Lake",
        "A Wraith, A Mummy, A Zombie Walk Into a Bar",
        "Henry Selick",
        "Witches in Love",
        "Spiders",
        "Halloween Party",
      ]
    }
  }
}
</script>

<style scoped>
li {
  display: inline;
}

ul {
  list-style-type: none;
}
</style>
