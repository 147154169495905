<template>
  <div>
    <day-wrapper :seed="seed" :reload="loadData">
      <div>
        <div v-for="(text, idx) in result" :key="idx">
          {{text}}
        </div>
      </div>
    </day-wrapper>
  </div>
</template>

<script>
import DayWrapper from './DayWrapper'
import request from 'superagent'
export default {
  components: {
    DayWrapper
  },
  data: function() {
    return {
      seed: "",
      result: [
      ]
    };
  },
  mounted () {
    this.loadData()
  },
  methods: {
    loadData() {
      request.get(`http://localhost:3002/api/${this.$route.meta.day}`)
        .set('accept', 'json')
        .end((err, res) => {
          this.seed = res.body.seed
          this.result = res.body.result
        })
    }
  },
  watch:{
    $route (to, from){
      this.loadData()
    }
  } 
};
</script>

<style>

</style>
